import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;
const ThankYouPage = () => {
  const { senderName } = useParams(); // Get the senderName parameter from the URL
  const [senderNameState, setSenderNameState] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setSenderNameState(senderName);
  }, []);

  //   if (!senderName) {
  //     // If senderName parameter is missing or empty, redirect to main page
  //     navigate("/");
  //   }
  return <Wrapper>Thank you! We will contact you soon</Wrapper>;
};

export default ThankYouPage;
