// AuthContext.js
import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const userInfoString = sessionStorage.getItem("UserInfo");

  // Parse the JSON string into a JavaScript object
  const userInfoObject = JSON.parse(userInfoString);

  // Access the "token" property from the object

  var token;
  if (userInfoString) {
    // console.log(userInfoObject.token)
    token = userInfoObject.token;
  }

  const [adminLoginToken, setAdminLoginToken] = useState(token);
  //   console.log("Token: ", token);
  const loginAdmin = (data) => {
    sessionStorage.setItem("UserInfo", JSON.stringify(data));

    setIsAdminAuthenticated(true);
    // console.log("After login, Data.token: ", data.token);
    setAdminLoginToken(data.token);
  };

  const logoutAdmin = () => {
    setIsAdminAuthenticated(false);
    sessionStorage.removeItem("UserInfo");
    window.location.reload(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAdminAuthenticated,
        adminLoginToken,
        setAdminLoginToken,
        loginAdmin,
        logoutAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
