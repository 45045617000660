import { createClient } from "@sanity/client";
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

const projectId = process.env.REACT_APP_SANITY_PROJECT_ID; 
const dataset = process.env.REACT_APP_SANITY_DATASET;

export const client = createClient({
  projectId,
  dataset,
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: "2023-05-03", // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
});

// uses GROQ to query content: https://www.sanity.io/docs/groq

//? To get the all the blogs
// export async function getPosts() {
//   const posts = await client.fetch(`*[_type == "post"]{
//     _id,
//     title,
//     slug,
//     mainImage{
//         asset->{
//             _id,
//             url
//         },
//         alt
//     },
//     body
//   }`);
//   return posts;
// }

// export async function createPost(post: Post) {
//   const result = client.create(post);
//   return result;
// }

// export async function updateDocumentTitle(_id, title) {
//   const result = client.patch(_id).set({ title });
//   return result;
// }
