import React from "react";
import styled from "styled-components";
import whatsappLogo from "../Images/whatsapp-logo.svg";
const Wrapper = styled.section`
  cursor: pointer;

  position: fixed;
  right: 30px;
  bottom: 30px;
  ${"" /* width: 100%; */}
  z-index: 5;
  div {
    display: flex;
    justify-content: end;
  }
  img {
    height: 70px;
  }

  @media only screen and (max-width: 767px) {
    right: 20px;
    bottom: 20px;
    img {
      height: 50px;
    }
  }
`;
const WhatsappButton = () => {
  return (
    <Wrapper>
      <a href="https://wa.me/9822923068" target="_blank" rel="noreferrer">
        <div>
          {/* WhatsappButton */}
          <img src={whatsappLogo} alt="whatsapp-us"></img>
        </div>
      </a>
    </Wrapper>
  );
};

export default WhatsappButton;
