import React, { useEffect, useRef, useState } from "react";
import {
  FaBars,
  FaFacebook,
  FaInstagram,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import LogoImage from "../Images/Traveller_Monks_Logo.webp";

//? Styled Components
const Wrapper = styled.section`
  --mainColor: #00afd1;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
  }
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    ${
      "" /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    }
  }

  nav a {
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
    font-size: 1.5rem;
    position: relative;
    text-align: center;
    font-weight: 550;
  }
  nav a.navlinks:before,
  nav a.navlinks:after {
    content: " ";
    position: absolute;
    height: 3px;
    width: 0;
    background-color: #fff;
    transition: 0.5s;
  }
  nav a.navlinks:after {
    left: 0;
    bottom: -10px;
  }
  nav a.navlinks:before {
    right: 0;
    top: -10px;
  }
  nav a.navlinks:hover {
    ${
      "" /* transform: scaleX(1.1);
    transition: 0.2s all ease;
    font-weight: 600; */
    }
  }
  nav a.navlinks:hover:after,
  nav a.navlinks:hover:before {
    width: 100%;
  }

  header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    ${"" /* visibility: hidden; */}
    display: none;
    opacity: 0;
    font-size: 1.8rem;
  }
  .logo {
    height: 80px;
  }
  .mobile-logo {
    display: none;
  }
  input {
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    outline: none;
  }
  .event-names-container {
    position: fixed;
    top: 80px;
    right: 0.5rem;
    padding: 1rem;
    width: 30vw;
    box-shadow: rgb(44, 44, 44) 1px 1px 10px 1px;
    max-height: 60vh;
    overflow: scroll;

    border-radius: 1rem;
    background: white;
    flex-direction: column;
    display: none;
    justify-content: start;
    align-items: start;
    color: white;
    text-decoration: none;

    a {
      text-decoration: none;
      color: #00afd1;

      li {
        color: #00afd1;
        text-decoration: none;
        padding-bottom: 1rem;
        ${"" /* padding-left: 1rem; */}
      }

      li:hover {
        font-weight: bold;
      }
    }
  }
  header div,
  nav {
    display: flex;
    align-items: center;
  }
  header div {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0.5rem;
  }
  .FaSearch {
    display: none;
  }
  .discount-bar {
    text-align: center;
    padding: 20px 5px;
    z-index: 5;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Ensure relative positioning for absolute positioning of spans */
    background-color: #ffeb3b; /* Bright yellow background */
    color: #000; /* Black text color for contrast */
    font-size: 1.3em; /* Larger font size */
    text-align: center; /* Center the text */

    font-weight: bold; /* Bold text */
  }

  .line1,
  .line2 {
    position: absolute;
    opacity: 0;
  }

  .line1 {
    animation-name: moveLine1;
    animation-duration: 3s;
  }

  .line2 {
    width: 100%;
    animation-name: moveLine2;
    animation-duration: 7s;
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    .vertical-bar {
      margin: 0px 10px;
    }
  }

  @keyframes moveLine1 {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    10%,
    85% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  @keyframes moveLine2 {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    2%,
    98% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  @media only screen and (max-width: 1024px) {
    .FaSearch {
      display: block;
    }
    header .nav-btn {
      display: block;
      opacity: 1;
    }
    .logo {
      height: 60px;
    }
    .mobile-logo {
      display: block;

      height: 80px;
    }
    header {
      height: 60px;
      padding: 0 0.7rem 0 0.7rem;
    }

    header nav {
      position: fixed;
      top: -100vh;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 25vh 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      background-color: var(--mainColor);
      transition: all 0.3s;
      z-index: 6;
    }

    header .responsive_nav {
      transform: translateY(100vh);
      height: 100vh;
    }

    nav .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    nav a {
      font-size: 1.5rem;
    }
    .event-names-container {
      width: 70vw;
      top: 70px;
    }

    input {
      display: none;
      width: 80%;
      padding: 0.3rem;
      font-size: 0.8rem;
      animation: fadeIn 0.5s;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .discount-bar {
      text-align: center;
      padding: 20px 5px;
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 330px) {
     {
      .line2 {
        flex-direction: column;
        .vertical-bar {
          display: none;
        }
      }
    }
  }
`;
const Navbar = ({ eventListState }) => {
  const [showLine1, setShowLine1] = useState(true);
  const [showDiscountBar, setShowDiscountBar] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navRef = useRef();
  const searchInputRef = useRef();
  const searchButtonRef = useRef();
  const eventNamesContainerRef = useRef();

  useEffect(() => {
    const delay = showLine1 ? 3000 : 5000;
    const interval = setInterval(() => {
      setShowLine1((prevState) => !prevState); // Toggle between true and false
    }, delay); // Change span every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  });
  useEffect(() => {
    const handleScroll = (event) => {
      if (
        eventNamesContainerRef.current &&
        !eventNamesContainerRef.current.contains(event.target)
      ) {
        eventNamesContainerRef.current.style.display = "none";
        searchInputRef.current.blur();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleContainerScroll = (event) => {
      const container = eventNamesContainerRef.current;
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight &&
        container.scrollTop > 0
      ) {
        event.preventDefault();
      }
    };

    const container = eventNamesContainerRef.current;
    container.addEventListener("scroll", handleContainerScroll);

    return () => {
      container.removeEventListener("scroll", handleContainerScroll);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("admin-dashboard")) {
      setShowDiscountBar(false);
    }
    // console.log(window.location.href);
  }, []);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const showSearchInput = () => {
    searchInputRef.current.style.display = "block";
    searchButtonRef.current.style.display = "none";
    searchInputRef.current.focus(); // Focus on the search input
  };

  // const showEvents = () => {
  //   // console.log(eventList);
  //   eventNamesContainerRef.current.style.display = "flex";
  // };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredEvents = eventListState.filter((event) =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Wrapper>
      <header>
        <Link to="/">
          <img src={LogoImage} className="logo" alt="Traveller Monks Logo" />
        </Link>
        <nav ref={navRef}>
          <Link to="/">
            <img
              src={LogoImage}
              className="mobile-logo"
              alt="Traveller Monks Logo"
              onClick={showNavbar}
            />
          </Link>

          <Link to="/" onClick={showNavbar} className="navlinks">
            Home
          </Link>
          <Link to="/treks" onClick={showNavbar} className="navlinks">
            Treks
          </Link>
          <Link to="/waterfall-treks" onClick={showNavbar} className="navlinks">
            Waterfall Treks
          </Link>
          <Link to="/tours" onClick={showNavbar} className="navlinks">
            Tours
          </Link>
          {/* <Link to="/events" onClick={showNavbar} className="navlinks">
            Events
          </Link> */}
          {/* <Link to="/blogs" onClick={showNavbar} className="navlinks">
            Blogs
          </Link> */}
          <Link to="/contact-us" onClick={showNavbar} className="navlinks">
            Contact Us
          </Link>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
          <div>
            <a
              style={{ marginRight: "-30px" }}
              href="https://www.instagram.com/traveller_monks/"
              target="traveller_monks"
            >
              <FaInstagram fontSize={30} />
            </a>
            <a href="https://www.instagram.com/traveller_monks">
              <FaFacebook fontSize={30} />
            </a>
          </div>
        </nav>
        <div>
          <div>
            <input
              type="text"
              placeholder="Search an event"
              ref={searchInputRef}
              onChange={handleSearchInputChange}
              onFocus={() =>
                (eventNamesContainerRef.current.style.display = "flex")
              }
              onBlur={(e) => {
                if (!eventNamesContainerRef.current.contains(e.relatedTarget)) {
                  eventNamesContainerRef.current.style.display = "none";
                  searchInputRef.current.value = "";
                }
              }}
            ></input>
            <div ref={searchButtonRef}>
              <FaSearch
                cursor="pointer"
                fontSize={20}
                className="FaSearch"
                // ref={searchButtonRef}
                onClick={showSearchInput}
              />
            </div>
            <div className="event-names-container" ref={eventNamesContainerRef}>
              {filteredEvents.length === 0 ? (
                <p style={{ color: "#00afd1" }}>No Events found</p>
              ) : (
                filteredEvents.map((event, index) => (
                  <Link
                    key={index}
                    to={`${process.env.REACT_APP_FRONTEND_URL}/event/${event.slug}`}
                    onClick={() => {
                      eventNamesContainerRef.current.style.display = "none";
                      searchInputRef.current.value = "";
                      setSearchQuery(""); // Clear search query after clicking on an event
                    }}
                  >
                    <li className="individual-event">{event.title}</li>
                  </Link>
                ))
              )}
            </div>
          </div>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars fontSize={20} />
          </button>
        </div>
      </header>
      {showDiscountBar && (
        <div className="discount-bar">
          {showLine1 ? (
            <span className="line1">Group Discount Available😍</span>
          ) : (
            <span className="line2">
              <p>Group of 3: 50₹ OFF </p>
              <p className="vertical-bar">|</p>
              <p> Group of 7: 100₹ OFF</p>
            </span>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default Navbar;
