import React, { Suspense } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Navbar from "./Components/Navbar";

import error from "./Animations/404.json";
import Lottie from "react-lottie";
import WhatsappButton from "./Components/WhatsappButton";
import { AuthProvider } from "./Config/ContextProvider";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { client } from "./Components/SanityClientComponent";
import ThankYouPage from "./Pages/ThankYouPage";

// Lazy load route components
const Homepage = React.lazy(() => import("./Pages/Homepage"));
const Treks = React.lazy(() => import("./Pages/Treks"));
const Tours = React.lazy(() => import("./Pages/Tours"));
const WaterfallTreks = React.lazy(() => import("./Pages/WaterfallTreks"));
const Contact = React.lazy(() => import("./Pages/Contact"));
const PaymentSuccess = React.lazy(() => import("./Pages/PaymentSuccess"));
const AdminPage = React.lazy(() => import("./Pages/AdminPage"));
const AdminLoginPage = React.lazy(() => import("./Pages/AdminLoginPage"));
const SingleEvent = React.lazy(() => import("./Components/SingleEvent"));

const Wrapper = styled.section`
  font-family: "Raleway", sans-serif;
`;

function App() {
  //! fetching the event data from the sanity

  const [eventListState, setEventListState] = useState([]);

  useEffect(() => {
    // console.log("app.js: fetch request for navbar contents");

    client
      .fetch(
        `*[_type == "event"]{
         _id,
        title,
        slug,
    }`
      )
      .then((data) => {
        const eventList = data.map((post) => {
          // console.log(post);
          return {
            title: post.title,
            slug: post.slug.current,
          };
        });

        // Set the event list using the array of objects
        setEventListState(eventList);
      })
      .catch(console.error);
  }, []);
  //! fetching code complete

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: error,
    rendererSettings: {
      preserveAspectRation: "xMidYMid slice",
    },
  };
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Navbar eventListState={eventListState} />
          <Wrapper>
            <WhatsappButton />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Homepage />} exact />
                <Route path="/treks" element={<Treks />} />
                <Route path="/tours" element={<Tours />} />
                <Route path="/waterfall-treks" element={<WaterfallTreks />} />
                <Route path="/event/:slug" element={<SingleEvent />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                <Route
                  path="/admin-dashboard"
                  element={<AdminPage page={"home"} />}
                />
                <Route
                  path="/admin-dashboard/editbooking"
                  element={<AdminPage page={"editbooking"} />}
                />
                <Route
                  path="/admin-dashboard/addbooking"
                  element={<AdminPage page={"addbooking"} />}
                />
                <Route path="/admin-login" element={<AdminLoginPage />} />
                <Route
                  exact
                  path="/thank-you/:name"
                  element={<ThankYouPage />}
                />
                <Route
                  path="*"
                  element={
                    <div
                      style={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        style={{
                          padding: "0",
                          margin: "5px",
                          zIndex: "0",
                        }}
                        width="80vw"
                      />
                    </div>
                  }
                />
                <Route
                  path="/event/*"
                  element={
                    <div
                      style={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        style={{
                          padding: "0",
                          margin: "5px",
                          zIndex: "0",
                        }}
                        width="80vw"
                      />
                    </div>
                  }
                />
              </Routes>
            </Suspense>
          </Wrapper>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
